import { Vue, Watch, Ref, Prop } from 'vue-property-decorator';
import { customerModule } from '@/store/modules/customer';
import { savedSearchModule } from '@/store/modules/savedSearch';
import RegisterOrCreateCustomer from '@/components/RegisterOrCreateCustomer.vue';
import { Component } from 'vue-property-decorator';

@Component({
    components: {
        RegisterOrCreateCustomer
    }
})
export default class DialogMixin extends Vue {
    @Ref() readonly registerOrCreate!: any;
    @Prop({ type: Boolean, default: true }) readonly emailRequired!: boolean;

    get selectedCustomer() {
        if (
            this.isASavedSearch &&
            !customerModule.selectedCustomer &&
            this.type === 0
        ) {
            return this.customers[0];
        }
        return customerModule.selectedCustomer;
    }

    set selectedCustomer(value: any) {
        customerModule.selectCustomer(value);
    }

    get isASavedSearch() {
        return !!savedSearchModule.savedSearchSelected;
    }

    get customers() {
        return savedSearchModule.customers;
    }

    active = 0;
    type = 0; // type = 1 "registrar cliente" - type = 2 "buscar cliente"

    @Watch('selectedCustomer', {
        deep: true
    })
    handleCustomer(customer: any) {
        if (this.type === 2 && customer) {
            this.active = 1;
        }
    }

    nextFirstSetp() {
        if (this.type === 2) {
            this.registerOrCreate.sendData();
        } else {
            this.active++;
        }
    }

    destroyed() {
        customerModule.selectCustomer();
    }
}
