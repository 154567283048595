var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"d-flex flex-column",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('p',{staticClass:"f-14 mb-1 text-justify",domProps:{"innerHTML":_vm._s(_vm.title)}}),(_vm.isASavedSearch)?[_c('div',[_c('el-radio',{staticClass:"py-2",attrs:{"label":0},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_vm._v(" Contacto de la búsqueda guardada ")])],1),(_vm.selectedItem === 0)?_c('div',{staticClass:"px-1 px-md-4 pb-2"},[_c('select-by-object',{attrs:{"items":_vm.customers,"disabled":_vm.customers.length < 2,"value-string":"fullName","value-key":"_id"},model:{value:(_vm.customerSavedSearch),callback:function ($$v) {_vm.customerSavedSearch=$$v},expression:"customerSavedSearch"}}),(_vm.validateData)?_c('fill-information',{staticClass:"mt-3",attrs:{"class-label-document":"w-100 mb-2 f-14 text-info","class-select-document":[
                    { 'col-12 px-0': _vm.customerSavedSearch.documentNumber },
                    'col-4 pl-0'
                ],"customer":_vm.customerSavedSearch,"class-input-document":"col-8 px-0","data":_vm.form},on:{"update:data":function($event){_vm.form=$event}}}):_vm._e()],1):_vm._e()]:_vm._e(),_c('div',[_c('el-radio',{staticClass:"py-2 d-inline-block m-0",attrs:{"label":1},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_vm._v(" Prospecto de mi cartera ")])],1),(_vm.selectedItem === 1)?_c('div',{staticClass:"px-1 px-md-4 pb-2 d-flex flex-column"},[_c('ValidationProvider',{attrs:{"rules":"required"}},[_c('auto-complete-customer',{model:{value:(_vm.form._id),callback:function ($$v) {_vm.$set(_vm.form, "_id", $$v)},expression:"form._id"}})],1),(_vm.selectedCustomer)?_c('div',{staticClass:"row"},[_c('p',{staticClass:"mt-3 mb-2 text-info f-14 w-100"},[_vm._v(" Prospecto seleccionado: ")]),_c('div',{staticClass:"bg-light-3 py-2 px-3 f-13 text-dark w-100 mb-3"},[_vm._v(" "+_vm._s(_vm.selectedCustomer.fullName)+" ")]),(!_vm.selectedCustomer.email && _vm.showEmailInput)?_c('ValidationProvider',{attrs:{"name":"correo electrónico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('p',{staticClass:"mt-3 mb-2 text-info f-14"},[_vm._v("Email del prospecto:")]),_c('el-input',{attrs:{"type":"email","placeholder":"Ingrese correo electrónico"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.validateData)?_c('fill-information',{staticClass:"mb-3",attrs:{"class-label-document":"w-100 mb-2 f-14 text-info","class-select-document":[
                    { 'col-12 px-0': _vm.selectedCustomer.documentNumber },
                    'col-4 pl-0'
                ],"customer":_vm.selectedCustomer,"class-input-document":"col-8 px-0","data":_vm.form},on:{"update:data":function($event){_vm.form=$event}}}):_vm._e()],1):_vm._e()],1):_vm._e(),_c('div',[_c('el-radio',{staticClass:"py-2 d-inline-block",attrs:{"label":2},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[_vm._v(" Registrar Prospecto Nuevo ")])],1),(_vm.selectedItem === 2)?_c('div',{staticClass:"px-1 px-md-4 d-flex flex-wrap pt-2"},[_c('select-document',{staticClass:"col-4 mb-3 pl-0",attrs:{"name":"tipo de documento","rules":"required"},model:{value:(_vm.form.documentType),callback:function ($$v) {_vm.$set(_vm.form, "documentType", $$v)},expression:"form.documentType"}}),_c('input-document',{staticClass:"col-8 mb-3 px-0",attrs:{"name":"numero de documento","rules":{ required: _vm.documentRequired },"type-doc":_vm.form.documentType},model:{value:(_vm.form.documentNumber),callback:function ($$v) {_vm.$set(_vm.form, "documentNumber", $$v)},expression:"form.documentNumber"}}),_c('ValidationProvider',{staticClass:"col-12 mb-3 px-0",attrs:{"name":"correo electrónico","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('el-input',{attrs:{"type":"email","placeholder":"Ingrese correo electrónico"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-12 mb-3 px-0",attrs:{"name":"apellido paterno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('el-input',{attrs:{"placeholder":"Ingrese apellido paterno"},model:{value:(_vm.form.firstSurname),callback:function ($$v) {_vm.$set(_vm.form, "firstSurname", $$v)},expression:"form.firstSurname"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-12 mb-3 px-0",attrs:{"name":"apellido materno","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('el-input',{attrs:{"placeholder":"Ingrese apellido materno"},model:{value:(_vm.form.secondSurname),callback:function ($$v) {_vm.$set(_vm.form, "secondSurname", $$v)},expression:"form.secondSurname"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"col-12 px-0 mb-0",attrs:{"name":"nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('el-input',{attrs:{"placeholder":"Ingrese los nombres"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_vm._t("actions",null,{"invalid":invalid,"send":_vm.send})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }