



























































































































































































import {
    Component,
    Prop,
    Ref,
    Watch,
    PropSync,
    Mixins
} from 'vue-property-decorator';
import AutoCompleteCustomer from '@/components/AutoCompleteCustomer.vue';
import SelectByObject from './SelectByObject.vue';
import { customerModule } from '@/store/modules/customer';
import { savedSearchModule } from '@/store/modules/savedSearch';
import FillInformationMixin from '@/mixins/fill-information';
import { ValidationObserver } from 'vee-validate';
import { Customer } from '@/interfaces/customer';

@Component({
    components: {
        AutoCompleteCustomer,
        SelectByObject,
        FillInformation: () =>
            import('@/components/DialogAppointment/FillInformation.vue'),
        SelectDocument: () => import('@/components/SelectDocument.vue'),
        InputDocument: () => import('@/components/InputDocument.vue')
    }
})
export default class RegisterOrCreateCustomer extends Mixins(
    FillInformationMixin
) {
    @Prop({ type: String }) title!: string;
    @PropSync('active', { type: Number, default: 1 }) step!: number;
    @PropSync('type', { type: Number, default: 0 })
    private selectedItem!: number;
    @Prop({ type: Boolean }) private blockedFirstStep!: boolean;
    @Prop({ default: false, type: Boolean }) private showEmailInput!: boolean;
    @Prop({ default: true, type: Boolean }) readonly documentRequired!: boolean;
    @Prop({ default: false, type: Boolean }) private validateData!: boolean;
    @Ref() observer!: InstanceType<typeof ValidationObserver>;

    form: Partial<Customer> = {};

    get customerSavedSearch() {
        if (!this.selectedCustomer) return this.customers[0];
        return this.selectedCustomer;
    }

    set customerSavedSearch(customer: any) {
        customerModule.selectCustomer(customer);
    }

    get isASavedSearch() {
        return (
            !!savedSearchModule.savedSearchSelected && !this.blockedFirstStep
        );
    }

    get customers() {
        return savedSearchModule.customers;
    }

    get selectedCustomer() {
        return customerModule.selectedCustomer;
    }

    mounted() {
        if (this.blockedFirstStep || !this.isASavedSearch) {
            this.selectedItem = 1;
        }
    }

    async send() {
        try {
            if (this.selectedItem === 2) {
                await customerModule.customerRegister(this.form);
            } else if (
                this.selectedItem === 1 &&
                this.selectedCustomer &&
                ((!this.selectedCustomer.email && this.showEmailInput) ||
                    (!this.selectedCustomer.documentType &&
                        this.validateData) ||
                    (!this.selectedCustomer.documentNumber &&
                        this.validateData))
            ) {
                await this.saveCustomer(this.selectedCustomer, this.form);
            } else if (
                this.selectedItem === 0 &&
                this.customerSavedSearch &&
                ((!this.customerSavedSearch.email && this.showEmailInput) ||
                    (!this.customerSavedSearch.documentType &&
                        this.validateData) ||
                    (!this.customerSavedSearch.documentNumber &&
                        this.validateData))
            ) {
                await this.saveCustomer(
                    {
                        ...this.customerSavedSearch,
                        _id: this.customerSavedSearch.personaId
                    },
                    this.form
                );
            }
            this.step++;
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message: error.mensaje
            });
        }
    }

    @Watch('selectedItem') changeType() {
        this.change();
    }

    change() {
        this.form = { documentType: 'dni' };
        customerModule.selectCustomer();
        this.$nextTick(() => {
            this.observer.reset();
        });
    }
}
