import { Customer } from '@/interfaces/customer';
import { customerModule } from '@/store/modules/customer';
import { savedSearchModule } from '@/store/modules/savedSearch';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class FillInformationMixin extends Vue {
    async saveCustomer(customer: Customer, form: Partial<Customer>) {
        if (customer.type === 'principal') {
            await customerModule.update({
                _id: customer._id,
                documentNumber: form.documentNumber,
                documentType: form.documentType
            });
        } else {
            await customerModule.updateAssociated({
                _id: customer._id,
                documentNumber: form.documentNumber,
                documentType: form.documentType
            });
        }
        
        if (savedSearchModule.savedSearchSelected) {
            await savedSearchModule.setCustomers({
                ...customer,
                ...form
            });
        }

        await customerModule.selectCustomer({
            ...customer,
            ...form
        });
        await customerModule.updateSearchCache(form);
    }
}
